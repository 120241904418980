import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";

const OwnerCard = ({
  owner,
  index,
  handleDeleteOwner = () => {},
  handleUpdateOwner = () => {},
  showEditButtons = true,
}) => {
  const [edit, setEdit] = useState(false);
  const [newOwner, setNewOwner] = useState(false);

  useEffect(() => {
    if (owner?.name) {
      setNewOwner(false);
    } else {
      setNewOwner(true);
    }
  }, []);

  return (
    <Card className="my-2 border">
      <CardBody className="position-relative">
        {!edit && !newOwner ? (
          <>
            {showEditButtons && (
              <div
                className="ms-auto  position-absolute d-flex gap-2"
                style={{ width: "fit-content", right: "8px" }}
              >
                <Button
                  outline
                  color="primary"
                  size="sm"
                  onClick={() => setEdit(true)}
                  className="d-inline-flex align-items-center gap-1"
                >
                  <i class="ri-pencil-fill "></i> Edit
                </Button>

                <Button
                  outline
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteOwner(index)}
                  className="d-inline-flex align-items-center gap-1"
                >
                  <i class="ri-delete-bin-6-fill"></i> Delete
                </Button>
              </div>
            )}
            <CardTitle tag="h5"> {owner?.name || ""} </CardTitle>
            <CardText className="mb-0">
              Address:{" "}
              {`${owner?.address?.street}${
                owner?.address?.city ? `, ${owner?.address?.city}` : ""
              } ${owner?.address?.state ? `, ${owner?.address?.state}` : ""} ${
                owner?.address?.zip
              }`}
            </CardText>
            <CardText>
              Responsible:
              {owner?.responsible ? (
                <Badge className="ms-2" color="success">
                  Yes
                </Badge>
              ) : (
                <Badge className="ms-2" color="danger">
                  No
                </Badge>
              )}
            </CardText>
          </>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: owner?.name || "",
              responsible: owner?.responsible || false,

              address: {
                street: owner?.address?.street || "",
                city: owner?.address?.city || "",
                state: owner?.address?.state || "",
                zip: owner?.address?.zip || "",
              },
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Owner Name is required"),
              responsible: Yup.bool().typeError(
                "Responsible can be true or false"
              ),
              address: Yup.object().shape({
                street: Yup.string(),
                city: Yup.string(),
                state: Yup.string().length(
                  2,
                  "State must be a two-letter string"
                ),
                zip: Yup.string().matches(
                  /^\d{5}(-\d{4})?$/,
                  "Zip must be formatted as 5 digits or 5 digits followed by a hyphen and 4 digits (e.g., 12345 or 12345-6789)"
                ),
              }),
            })}
            onSubmit={(values) => {
              handleUpdateOwner(index, values);
              setNewOwner(false);
              setEdit(false);
            }}
          >
            {(validation) => (
              <>
                {/* </ModalHeader> */}
                <Form>
                  <div className="row g-3">
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <div>
                        <Label htmlFor="name" className="form-label">
                          Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Owner Name"
                          id="name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <FormGroup
                        check
                        inline
                        className="d-flex align-items-center gap-2 h-100"
                      >
                        <Input
                          name="responsible"
                          type="checkbox"
                          className=""
                          placeholder="Enter Responsible"
                          id="responsible"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.responsible}
                          invalid={
                            validation.touched.responsible &&
                            validation.errors.responsible
                              ? true
                              : false
                          }
                          defaultChecked={validation.values.responsible}
                        />
                        <Label className="mb-0" htmlFor="responsible">
                          Responsible
                        </Label>
                      </FormGroup>
                      {validation.touched.responsible &&
                      validation.errors.responsible ? (
                        <FormFeedback type="invalid">
                          {validation.errors.responsible}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm={12}>
                      <h3 className="h4 mb-0">Address</h3>
                    </Col>
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <div>
                        <Label htmlFor="address.street" className="form-label">
                          Street
                        </Label>
                        <Input
                          name="address.street"
                          type="text"
                          className="form-control"
                          placeholder="Enter Street"
                          id="address.street"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address.street || ""}
                          invalid={
                            validation.touched?.address?.street &&
                            validation.errors?.address?.street
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address?.street &&
                        validation.errors.address?.street ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address?.street}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <div>
                        <Label htmlFor="address.city" className="form-label">
                          City
                        </Label>
                        <Input
                          name="address.city"
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          id="address.city"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address.city || ""}
                          invalid={
                            validation.touched?.address?.city &&
                            validation.errors?.address?.city
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address?.city &&
                        validation.errors.address?.city ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address?.city}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <div>
                        <Label htmlFor="address.state" className="form-label">
                          State
                        </Label>
                        <Input
                          name="address.state"
                          type="text"
                          className="form-control"
                          placeholder="Enter State"
                          id="address.state"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address.state || ""}
                          invalid={
                            validation.touched?.address?.state &&
                            validation.errors?.address?.state
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address?.state &&
                        validation.errors.address?.state ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address?.state}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={4} xxl={3}>
                      <div>
                        <Label htmlFor="address.zip" className="form-label">
                          Zip
                        </Label>
                        <Input
                          name="address.zip"
                          type="text"
                          className="form-control"
                          placeholder="Enter ZIP"
                          id="address.zip"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address.zip || ""}
                          invalid={
                            validation.touched?.address?.zip &&
                            validation.errors?.address?.zip
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address?.zip &&
                        validation.errors.address?.zip ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address?.zip}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <div className="col-lg-12">
                      <div className="hstack w-100 gap-2 justify-content-end">
                        <Button
                          color="light"
                          onClick={() => {
                            if (newOwner) {
                              handleDeleteOwner(index);
                            } else {
                              setEdit(false);
                            }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          className="d-inline-flex align-items-center gap-2"
                          // disabled={isLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        )}
      </CardBody>
    </Card>
  );
};

export default OwnerCard;
