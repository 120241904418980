import React from "react";
import { Col, Button, Modal, ModalBody, Row } from "reactstrap";

const NEWS_DATA = [
  { label: "ID", value: "id" },
  { label: "Newspaper", value: "newspaper" },
  { label: "Contact", value: "contact" },
  { label: "Phone Number", value: "phoneNumber" },
  { label: "Fax Number", value: "faxNumber" },
  { label: "Notes", value: "notes" },
];

const NewsDetailsModal = ({
  news = {},
  isOpen,
  setIsOpen,
  onEditNews,
  permissions,
}) => {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h5 className="modal-title">News Details</h5>
          <Button
            type="button"
            onClick={() => onClose()}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody className="pt-0">
          {permissions?.news?.includes("update") && (
            <div className="d-flex justify-content-end">
              <Button
                className="ml-auto"
                color="success"
                onClick={onEditNews}
              >
                Edit News
              </Button>
            </div>
          )}
          {NEWS_DATA.map((d, i) => (
            <Row>
              <Col xs={4} sm={4} className="">
                <p key={i} className="font-weight-bold">
                  {d.label}
                </p>
              </Col>
              <Col xs={8} sm={8}>
                <p className="" key={i}>
                  {news[d.value] || "--"}
                </p>
              </Col>
            </Row>
          ))}
          <Row>
            <Col xs={4} sm={4} className="">
              <p className="font-weight-bold">Address</p>
            </Col>
            <Col xs={8} sm={8}>
              <p>
                {`${news?.address?.street}${
                  news?.address?.city ? `, ${news?.address?.city}` : ""
                } ${
                  news?.address?.state
                    ? `, ${news?.address?.state}`
                    : ""
                } ${news?.address?.zip}`}
              </p>
            </Col>
          </Row>
        </ModalBody>
      </>
    </Modal>
  );
};

export default NewsDetailsModal;
