import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
  FormGroup,
  Spinner,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  addNewCustomer,
  createRequest,
  deleteDocumentById,
  editCustomer,
  unlockRequestById,
  updateDocumentById,
  updateRequest,
  updateRequestById,
  updateRequestDocument,
  uploadRequestDocument,
} from "../../helpers/backend_helper";
import ReactSelect from "../../Components/Common/ReactSelect";
import OwnerCard from "./OwnerCard";

// {"documents":["Tow_Receipt.pdf"]}

const OWNER_SCHEMA = {
  name: "",
  responsible: false,
  address: {
    street: "",
    city: "",
    state: "",
    zip: "",
  },
};

const RequestModal = ({
  request = {},
  isOpen,
  setIsOpen,
  setActiveRequestIndex,
  loadRequestData,
  towReasons,
}) => {
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [deletingFilesLoader, setDeletingFilesLoader] = useState([]);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setOwners(request?.owners || []);
    } else {
      formikRef?.current?.resetForm();
    }
  }, [isOpen]);

  const updateDocument = async () => {
    try {
      const data = new FormData();
      data.append("file", file);
      await updateDocumentById(request?.id, file?.name, data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const uploadDocument = async (url) => {
    try {
      const data = new FormData();
      data.append("file", file);
      const res = await updateRequestDocument(url, data);
      console.log("file res", res);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const deleteDocument = async (d) => {
    try {
      if (!deletingFilesLoader?.includes(d)) {
        setDeletingFilesLoader((prev) => [...prev, d]);
      }
      await deleteDocumentById(request?.id, d);
      setIsLoading(false);
      await loadRequestData();
      setDeletingFilesLoader((prev) => prev.filter((n) => n !== d));
    } catch (err) {}
  };

  const onSubmit = async (values, formikRef) => {
    setIsLoading(true);
    const data = { ...values };
    data.owners = [...owners];

    try {
      let res = {};
      if (request?.id) {
        res = await updateRequest({ id: request?.id, ...data });
        if (file) {
          await updateDocument();
        }
      } else {
        // if (!file) {
        //   formikRef.setFieldError("documents", "Please Upload PDF file.");
        //   return;
        // }
        const res = await createRequest(data);
        if (res?.presignedURL) {
          await uploadDocument(res?.presignedURL);
        }
        if (res?.id) {
          setIsOpen(false);
        }
      }
      await loadRequestData();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = (formik) => {
    formikRef?.current?.resetForm();
    setIsOpen(false);
    setActiveRequestIndex(-1);
  };

  // Owners Handling

  const handleAddNewOwner = () => {
    setOwners((prev) => [...prev, OWNER_SCHEMA]);
  };

  const handleDeleteOwner = (index) => {
    const tempOwners = [...owners];
    tempOwners.splice(index, 1);

    setOwners(tempOwners);
  };

  const handleUpdateOwner = (index, value) => {
    const tempOwners = [...owners];
    tempOwners[index] = value;
    setOwners(tempOwners);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "1200px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          chattel: {
            vin: request?.chattel?.vin || "",
            plate: request?.chattel?.plate || "",
            state: request?.chattel?.state || "",
            year: request?.chattel?.year || "",
            make: request?.chattel?.make || "",
            model: request?.chattel?.model || "",
            style: request?.chattel?.style || "",
          },
          customerID: request?.customerID || "",
          service: {
            date: request?.service?.date || "",
            reason: request?.service?.reason || "",
            amount: request?.service?.amount || "",
            dailyRate: request?.service?.dailyRate || "",
            status: request?.service?.status || "",
            referenceID: request?.service?.referenceID || "",
            heavy: request?.service?.heavy || false,
            watercraft: request?.service?.watercraft || false,
            totaled: request?.service?.totaled || false,
            fee: request?.service?.fee || "",
            length: request?.service?.length || "",
          },
          documents: request?.documents || [],
          notes: request?.notes || "",
        }}
        validationSchema={Yup.object({
          chattel: Yup.object().shape({
            vin: Yup.string(),
            plate: Yup.string(),
            state: Yup.string(),
            year: Yup.number(),
            make: Yup.string(),
            model: Yup.string(),
            style: Yup.string(),
          }),

          customerID: Yup.string()
            .required("Customer Id is required")
            .matches(/^[a-zA-Z]+$/, "ID must only contain letters"),
          service: Yup.object().shape({
            date: Yup.string(),
            reason: Yup.string(),
            amount: Yup.number().typeError("Please Enter a number"),
            dailyRate: Yup.number().typeError("Please Enter a number"),
            status: Yup.string(),
            referenceID: Yup.string(),
            heavy: Yup.bool().typeError("Please Enter a boolean"),
            watercraft: Yup.bool().typeError("Please Enter a boolean"),
            totaled: Yup.bool().typeError("Please Enter a boolean"),
            fee: Yup.number().typeError("Please Enter a number"),
            length: Yup.string(),
          }),
          documents: Yup.array().of(Yup.string()),
          notes: Yup.string(),
        })}
        innerRef={formikRef}
        onSubmit={onSubmit}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">
                {request?.id ? "Edit" : "Add"} Request
              </h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="file" className="form-label">
                        Document
                      </Label>
                      <Input
                        name="file"
                        type="file"
                        id="fileInput"
                        accept="application/pdf"
                        className="form-control"
                        placeholder="Select Document"
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            setFile(e.target.files[0]);
                            validation.setFieldValue("documents", [
                              e.target.files[0]?.name,
                              ...validation.values.documents,
                            ]);
                            validation.setFieldError("documents", []);
                          }
                        }}
                      />
                      {validation.values.documents?.length > 0 && (
                        <p className="mb-1 mt-2">
                          {`Upload${request?.id ? "ed" : ""}`} File(s):{" "}
                        </p>
                      )}
                      {validation.values.documents?.map((d) => (
                        <div className="d-flex justify-content-between">
                          <span>{d}</span>
                          <span
                            onClick={() => {
                              if (request?.id) {
                                deleteDocument(d);
                              } else {
                                validation.setFieldValue("documents", []);
                                document.getElementById("fileInput").value = "";
                                setFile(null);
                              }
                            }}
                            className=" link-danger fs-15 "
                          >
                            {deletingFilesLoader.includes(d) ? (
                              <i className="ri-loader-line circular-spin"></i>
                            ) : (
                              <i className="ri-delete-bin-line cursor-pointer"></i>
                            )}
                          </span>
                        </div>
                      ))}
                      {validation.errors.documents ? (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.documents}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="customerID" className="form-label">
                        Customer ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="customerID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Customer ID"
                        id="customerID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.customerID || ""}
                        invalid={
                          validation.touched.customerID &&
                          validation.errors.customerID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.customerID &&
                      validation.errors.customerID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.customerID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* *******************  Vehicle Information  ************** */}
                  <Col sm={12}>
                    <h4>Vehicle Information</h4>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.vin" className="form-label">
                        VIN
                      </Label>
                      <Input
                        name="chattel.vin"
                        type="text"
                        className="form-control"
                        placeholder="Enter VIN"
                        id="chattel.vin"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.vin || ""}
                        invalid={
                          validation.touched?.chattel?.vin &&
                          validation.errors?.chattel?.vin
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.vin &&
                      validation.errors?.chattel?.vin ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.vin}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.plate" className="form-label">
                        Plate
                      </Label>
                      <Input
                        name="chattel.plate"
                        type="text"
                        className="form-control"
                        placeholder="Enter Plate"
                        id="chattel.plate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.plate || ""}
                        invalid={
                          validation.touched?.chattel?.plate &&
                          validation.errors?.chattel?.plate
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.plate &&
                      validation.errors?.chattel?.plate ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.plate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.state" className="form-label">
                        State
                      </Label>
                      <Input
                        name="chattel.state"
                        type="text"
                        className="form-control"
                        placeholder="Enter State"
                        id="chattel.state"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.state || ""}
                        invalid={
                          validation.touched?.chattel?.state &&
                          validation.errors?.chattel?.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.state &&
                      validation.errors?.chattel?.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.state}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.year" className="form-label">
                        Year
                      </Label>
                      <Input
                        name="chattel.year"
                        type="number"
                        className="form-control"
                        placeholder="Enter Year"
                        id="chattel.year"
                        min="yyyy-01-01"
                        max="yyyy-12-31"
                        step="1"
                        pattern="[0-9]{4}"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.year || ""}
                        invalid={
                          validation.touched?.chattel?.year &&
                          validation.errors?.chattel?.year
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.year &&
                      validation.errors?.chattel?.year ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.year}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.make" className="form-label">
                        Make
                      </Label>
                      <Input
                        name="chattel.make"
                        type="text"
                        className="form-control"
                        placeholder="Enter Make"
                        id="chattel.make"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.make || ""}
                        invalid={
                          validation.touched?.chattel?.make &&
                          validation.errors?.chattel?.make
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.make &&
                      validation.errors?.chattel?.make ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.make}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.model" className="form-label">
                        Model
                      </Label>
                      <Input
                        name="chattel.model"
                        type="text"
                        className="form-control"
                        placeholder="Enter Model"
                        id="chattel.model"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.model || ""}
                        invalid={
                          validation.touched?.chattel?.model &&
                          validation.errors?.chattel?.model
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.model &&
                      validation.errors?.chattel?.model ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.model}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="chattel.style" className="form-label">
                        Style
                      </Label>
                      <Input
                        name="chattel.style"
                        type="text"
                        className="form-control"
                        placeholder="Enter Style"
                        id="chattel.style"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.chattel?.style || ""}
                        invalid={
                          validation.touched?.chattel?.style &&
                          validation.errors?.chattel?.style
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.chattel?.style &&
                      validation.errors?.chattel?.style ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.chattel?.style}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {/* *******************  Service Information  ************** */}
                  <Col sm={12}>
                    <h4>Service Information</h4>
                  </Col>

                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="service.date" className="form-label">
                        Date
                      </Label>
                      <Input
                        name="service.date"
                        type="date"
                        className="form-control"
                        placeholder="Enter Auction Date"
                        id="service.date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.date || ""}
                        invalid={
                          validation.touched?.service?.date &&
                          validation.errors?.service?.date
                            ? true
                            : false
                        }
                        // disabled={request?.status !== "completed"}
                      />
                      {validation.touched?.service?.date &&
                      validation.errors?.service?.date ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <ReactSelect
                        labelName="Reason"
                        options={
                          towReasons?.length > 0
                            ? towReasons?.map((r) => ({
                                label: r,
                                value: r,
                              }))
                            : []
                        }
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="service.reason"
                        placeholder="Select Reason"
                      />

                      {validation.touched?.service?.reason &&
                      validation.errors?.service?.reason ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.reason}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="service.amount" className="form-label">
                        Service Amount
                      </Label>
                      <Input
                        name="service.amount"
                        type="number"
                        className="form-control"
                        placeholder="Enter Service Amount"
                        id="service.amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.amount || ""}
                        invalid={
                          validation.touched?.service?.amount &&
                          validation.errors?.service?.amount
                            ? true
                            : false
                        }
                        // disabled={request?.status !== "completed"}
                      />
                      {validation.touched?.service?.amount &&
                      validation.errors?.service?.amount ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.amount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="service.dailyRate" className="form-label">
                        Daily Rate
                      </Label>
                      <Input
                        name="service.dailyRate"
                        type="number"
                        className="form-control"
                        placeholder="Enter Daily Rate"
                        id="service.dailyRate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.dailyRate || ""}
                        invalid={
                          validation.touched?.service?.dailyRate &&
                          validation.errors?.service?.dailyRate
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.service?.dailyRate &&
                      validation.errors?.service?.dailyRate ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.dailyRate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <ReactSelect
                        labelName="Status"
                        options={[
                          { label: "Complete", value: "complete" },
                          { label: "Cancelled", value: "cancelled" },
                          { label: "In Progress", value: "in-progress" },
                        ]}
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="service.status"
                        placeholder="Select Status"
                        isError={validation.errors?.service?.status}
                      />
                      {validation.errors?.service?.status ? (
                        <span className="error">
                          {validation.errors?.service?.status}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label
                        htmlFor="service.referenceID"
                        className="form-label"
                      >
                        Reference ID
                      </Label>
                      <Input
                        name="service.referenceID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Reference ID"
                        id="service.referenceID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.referenceID || ""}
                        invalid={
                          validation.touched?.service?.referenceID &&
                          validation.errors?.service?.referenceID
                            ? true
                            : false
                        }
                      />
                      {validation.touched?.service?.referenceID &&
                      validation.errors?.service?.referenceID ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.referenceID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="service.heavy"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="service.heavy"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.heavy || ""}
                        invalid={
                          validation.touched?.service?.heavy &&
                          validation.errors?.service?.heavy
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="service.heavy">
                        Heavy
                      </Label>
                    </FormGroup>
                    {validation.touched?.service?.heavy &&
                    validation.errors?.service?.heavy ? (
                      <FormFeedback type="invalid">
                        {validation.errors?.service?.heavy}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="service.totaled"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="service.totaled"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.totaled || ""}
                        invalid={
                          validation.touched?.service?.totaled &&
                          validation.errors?.service?.totaled
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="service.totaled">
                        Totaled
                      </Label>
                    </FormGroup>

                    {validation.touched?.service?.totaled &&
                    validation.errors?.service?.totaled ? (
                      <FormFeedback type="invalid">
                        {validation.errors?.service?.totaled}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <FormGroup
                      check
                      inline
                      className="d-flex align-items-center gap-2 h-100"
                    >
                      <Input
                        name="service.watercraft"
                        type="checkbox"
                        className=""
                        placeholder="Enter Heavy"
                        id="service.watercraft"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.watercraft || ""}
                        invalid={
                          validation.touched?.service?.watercraft &&
                          validation.errors?.service?.watercraft
                            ? true
                            : false
                        }
                      />
                      <Label className="mb-0" htmlFor="service.watercraft">
                        Watercraft
                      </Label>
                    </FormGroup>
                    {validation.touched?.service?.watercraft &&
                    validation.errors?.service?.watercraft ? (
                      <FormFeedback type="invalid">
                        {validation.errors?.service?.watercraft}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="service.fee" className="form-label">
                        Fee
                      </Label>
                      <Input
                        name="service.fee"
                        type="number"
                        className="form-control"
                        placeholder="Enter Notice Fee"
                        id="service.fee"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.fee || ""}
                        invalid={
                          validation.touched?.service?.fee &&
                          validation.errors?.service?.fee
                            ? true
                            : false
                        }
                        // disabled={request?.status !== "completed"}
                      />
                      {validation.touched?.service?.fee &&
                      validation.errors?.service?.fee ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.fee}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={4} xxl={3}>
                    <div>
                      <Label htmlFor="service.length" className="form-label">
                        Length
                      </Label>
                      <Input
                        name="service.length"
                        type="text"
                        className="form-control"
                        placeholder="Enter Length"
                        id="service.length"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.service?.length || ""}
                        invalid={
                          validation.touched?.service?.length &&
                          validation.errors?.service?.length
                            ? true
                            : false
                        }
                        // disabled={request?.status !== "completed"}
                      />
                      {validation.touched?.service?.length &&
                      validation.errors?.service?.length ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.service?.length}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div>
                      <Label htmlFor="notes" className="form-label">
                        Notes
                      </Label>
                      <Input
                        name="notes"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Notes"
                        id="notes"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.notes || ""}
                        invalid={
                          validation.touched.notes && validation.errors.notes
                            ? true
                            : false
                        }
                      />
                      {validation.touched.notes && validation.errors.notes ? (
                        <FormFeedback type="invalid">
                          {validation.errors.notes}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
      <ModalBody className="pt-0">
        <div className="row g-3">
          {/* *******************  Owners  ************** */}

          <Col sm={12}>
            <h4>Owners</h4>
          </Col>
          {owners?.length > 0 ? (
            owners?.map((owner, index) => (
              <Col sm={12} className="mt-0">
                <OwnerCard
                  owner={owner}
                  index={index}
                  handleDeleteOwner={handleDeleteOwner}
                  handleUpdateOwner={handleUpdateOwner}
                />
              </Col>
            ))
          ) : (
            <Col sm={12}>
              <h5 className="text-center my-0">Please add a Owner</h5>
            </Col>
          )}

          <Col sm={12}>
            <Button outline color="primary" onClick={handleAddNewOwner}>
              <i class="ri-add-line me-2"></i>
              Add New Owner
            </Button>
          </Col>
          <div className="col-lg-12">
            <div className="hstack gap-2 justify-content-end">
              <Button color="light" onClick={() => onClose()}>
                Close
              </Button>
              <Button
                color="primary"
                className="d-inline-flex align-items-center gap-2"
                disabled={isLoading}
                onClick={() => formikRef?.current?.handleSubmit()}
              >
                {isLoading && <Spinner size="sm"> Loading... </Spinner>}{" "}
                <span>{request?.id ? "Update Request" : "Add Request"}</span>
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestModal;
