import React, { useEffect, useState, useMemo } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Spinner,
  Label,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";
import Pagination from "rc-pagination";
import DeleteModal from "./DeleteModal";
import DMVModal from "./DMVModal";
import DMVDetailsModal from "./DMVDetailsModal";
import { deleteDmv, getAllDmv } from "../../helpers/backend_helper";

const LIMIT = 20;
let searchTimeout = null;

const DMV = () => {
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const ITEMS_PER_PAGE = 20;
  const [dmvModalOpen, setDmvModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeDmvIndex, setActiveDmvIndex] = useState(-1);
  const [detailsModal, setDetailsModal] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeDeleteDmv, setActiveDeleteDmv] = useState({});

  const customPaginate = (data, itemsPerPage, pageNumber) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = data.slice(startIndex, endIndex);
    return paginatedItems;
  };

  useEffect(() => {
    if (!permissions?.customers?.includes("read")) {
      navigate("/");
    }
    loadDmvData();
  }, []);

  const loadDmvData = async (loading = true) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const res = await getAllDmv();
      setData(res);
      setRenderData(customPaginate(res, ITEMS_PER_PAGE, 1));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onPageChange = (current, pageSize) => {
    setCurrentPage(current);
    setRenderData(customPaginate(data, ITEMS_PER_PAGE, current));
  };

  const handleDelete = async () => {
    // const tempData = [...data];
    // // tempData.splice(activeDeleteDmv?.index, 1);
    // // setData(tempData);
    // // setRenderData(customPaginate(tempData, ITEMS_PER_PAGE, currentPage));
    try {
      await deleteDmv({ id: activeDeleteDmv?.id });
      await loadDmvData(false);
      setDeleteModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Section",
        accessor: "section",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Contact",
        accessor: "contact",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      // {
      //   Header: "Address",
      //   disableFilters: false,
      //   filterable: true,
      //   disableSortBy: true,
      //   Cell: (cellProps) => {
      //     const address = cellProps?.row?.original?.address;

      //     return (
      //       <div className="">
      //         {`${address?.street}${
      //           address?.city ? `, ${address?.city}` : ""
      //         } ${address?.state ? `, ${address?.state}` : ""} ${address?.zip}`}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "",
        Cell: (cellProps) => {
          const handleDetails = () => {
            setActiveDmvIndex(
              (currentPage - 1) * LIMIT + cellProps?.row?.index
            );
            setDetailsModal(true);
          };

          const handleDelete = async (e) => {
            e.stopPropagation();
            setActiveDeleteDmv({
              id: cellProps?.row?.original?.id,
              index: cellProps?.row?.index,
            });
            setDeleteModalOpen(true);
          };

          return (
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDetails}
                color="primary"
                className="me-2"
                outline
              >
                Details
              </Button>

              <Button onClick={handleDelete} color="danger" outline>
                Delete
              </Button>
            </div>
          );
        },
        accessor: "mcustcmt",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const onEditDmv = () => {
    setDetailsModal(false);
    setDmvModalOpen(true);
  };

  document.title = "DMVs | ALW";

  return (
    <React.Fragment>
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDelete={handleDelete}
      />
      <DMVModal
        dmv={activeDmvIndex > -1 ? data[activeDmvIndex] : {}}
        isOpen={dmvModalOpen}
        setIsOpen={setDmvModalOpen}
        setActiveDmvIndex={setActiveDmvIndex}
        loadDmvData={loadDmvData}
      />
      <DMVDetailsModal
        isOpen={detailsModal}
        dmv={activeDmvIndex > -1 ? data[activeDmvIndex] : {}}
        setIsOpen={setDetailsModal}
        onEditDmv={onEditDmv}
        permissions={permissions}
      />
      <div className="page-content">
        <Container fluid>
          <div className="customer-table-transition">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">DMVs</h4>

              <div className="page-title-right">
                {permissions?.customers?.includes("create") && (
                  <Button
                    color="success"
                    onClick={() => {
                      setDmvModalOpen(true);
                      setActiveDmvIndex(-1);
                    }}
                  >
                    Add DMV
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Row className="customer-row mx-0">
            <Col xxl={12} className="customer-table-transition">
              <Card id="contactList">
                <CardBody className="pt-0">
                  {isLoading ? (
                    <div className="py-5 d-flex justify-content-center">
                      <Spinner color="primary" />
                    </div>
                  ) : data?.length > 0 ? (
                    <div className="table-responsive">
                      <TableContainer
                        columns={columns}
                        data={renderData || []}
                        isBordered={false}
                        customPageSize={1500}
                        className="custom-header-css table align-middle table-nowrap"
                        tableClassName="table-centered align-middle table-nowrap mb-0"
                        theadClassName="text-muted table-light"
                        SearchPlaceholder="Search..."
                        onRowClick={(row) => {
                          setActiveDmvIndex(
                            (currentPage - 1) * LIMIT + row?.index
                          );
                          setDetailsModal(true);
                        }}
                        rowClickable
                        // customSortByCb={onCustomSortBy}
                        // customSortBy={sortBy}
                        manualSort
                        // divClass="table-responsive table-card mb-3"
                        // tableClass="align-middle table-nowrap"
                        // theadClass="table-light"
                      />
                      <Row className="gap-0 justify-content-md-between mx-0 justify-content-center align-items-center py-2">
                        <Col className="col-md-auto ps-0">
                          Showing{" "}
                          <strong> {(currentPage - 1) * LIMIT + 1}</strong> to{" "}
                          <strong>
                            {" "}
                            {(currentPage - 1) * LIMIT + LIMIT > data?.length
                              ? data?.length
                              : (currentPage - 1) * LIMIT + LIMIT}{" "}
                          </strong>
                          of <strong> {data?.length} </strong> results
                        </Col>
                        <Col
                          xs={6}
                          md={9}
                          className="d-flex justify-content-end  align-items-center py-2"
                        >
                          <Pagination
                            current={currentPage}
                            total={data?.length}
                            defaultPageSize={ITEMS_PER_PAGE}
                            onChange={onPageChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DMV;
