import moment from "moment";
import React from "react";
import { Col, Button, Modal, ModalBody, Row, Badge } from "reactstrap";
import OwnerCard from "./OwnerCard";

const REQUEST_DATA = [
  { label: "ID", value: "id" },
  { label: "Owner Name", value: "ownerName" },
  { label: "Notice Date", value: "noticeDate" },
  { label: "Notice Fee", value: "noticeFee" },
  { label: "Customer ID", value: "requestID" },
  { label: "Tow Amount", value: "towAmount" },
  { label: "Auction Fee", value: "auctionFee" },
  { label: "Totaled", value: "totaled" },
  { label: "Watercraft", value: "watercraft" },
  { label: "Lien Fee", value: "lienFee" },
  { label: "Heavy", value: "heavy" },
  { label: "Import Date", value: "importDate" },
  { label: "Reference ID", value: "referenceID" },
  { label: "Owner Address", value: "ownerAddress" },
  { label: "Storage Amount", value: "storageAmount" },
  { label: "Auction Date", value: "auctionDate" },
  { label: "Tow Reason", value: "towReason" },
  { label: "Tow Date", value: "towDate" },
  { label: "Lien Length", value: "lienLength" },
  { label: "Storage Rate", value: "storageRate" },
  { label: "State", value: "state" },
  {
    label: "Documents",
    value: "documents",
    formatter: (value) => {
      return (
        <>
          {value?.map?.((d) => (
            <p>{d}</p>
          ))}
        </>
      );
    },
  },
];

const Vehicle_Information = [
  { label: "VIN", value: "vin" },
  { label: "Plate", value: "plate" },
  { label: "State", value: "state" },
  { label: "Year", value: "year" },
  { label: "Make", value: "make" },
  { label: "Model", value: "model" },
  { label: "Style", value: "style" },
];

const Service_Information = [
  {
    label: "Date",
    value: "date",
    formatter: (date) => {
      return <p>{moment(date).format("DD MMM, YYYY")}</p>;
    },
  },
  { label: "Reason", value: "reason" },
  { label: "Amount", value: "amount" },
  { label: "Daily Rate", value: "dailyRate" },
  {
    label: "Status",
    value: "status",
    formatter: (status) => {
      return status === "complete" ? (
        <Badge color="success">Complete</Badge>
      ) : status === "cancelled" ? (
        <Badge color="danger">Cancelled</Badge>
      ) : status === "in-progress" ? (
        <Badge color="info">In Progress</Badge>
      ) : (
        "--"
      );
    },
  },
  { label: "Reference ID", value: "referenceID" },
  {
    label: "Heavy",
    value: "heavy",
    formatter: (value) => {
      return value ? (
        <Badge className="" color="success">
          Yes
        </Badge>
      ) : (
        <Badge className="" color="danger">
          No
        </Badge>
      );
    },
  },
  {
    label: "Watercraft",
    value: "watercraft",
    formatter: (value) => {
      return value ? (
        <Badge className="" color="success">
          Yes
        </Badge>
      ) : (
        <Badge className="" color="danger">
          No
        </Badge>
      );
    },
  },
  {
    label: "Totaled",
    value: "totaled",
    formatter: (value) => {
      return value ? (
        <Badge className="" color="success">
          Yes
        </Badge>
      ) : (
        <Badge className="" color="danger">
          No
        </Badge>
      );
    },
  },
  { label: "Fee", value: "fee" },
  { label: "Length", value: "length" },
];

const RequestDetails = ({
  request = {},
  isOpen,
  setIsOpen,
  onEditCustomer,
  permissions,
}) => {
  const onClose = () => {
    setIsOpen(false);
  };

  console.log("request", request);
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "850px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h5 className="modal-title">Request Details</h5>
          <Button
            type="button"
            onClick={() => onClose()}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody className="pt-0">
          {permissions?.requests?.includes("update") && (
            <div className="d-flex justify-content-end">
              <Button
                className="ml-auto"
                color="success"
                onClick={onEditCustomer}
              >
                Edit Request
              </Button>
            </div>
          )}
          <Row>
            <Col sm={12} md={6} className="">
              <Row>
                <Col xs={4} sm={4} className="">
                  <p className="font-weight-bold">Customer ID</p>
                </Col>
                <Col xs={8} sm={8}>
                  <p className="">{request?.customerID || "--"}</p>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6} className="">
              <Row>
                <Col xs={4} sm={4} className="">
                  <p className="font-weight-bold">Document</p>
                </Col>
                <Col xs={8} sm={8}>
                  {request?.documents?.map((d) => (
                    <p className="">{d || "--"}</p>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <h4 className="mb-3">Vehicle Information</h4>
            </Col>
            {Vehicle_Information.map((d, i) => (
              <Col sm={12} md={6} className="">
                <Row>
                  <Col xs={4} sm={4} className="">
                    <p key={i} className="font-weight-bold">
                      {d.label}
                    </p>
                  </Col>
                  <Col xs={8} sm={8}>
                    {d?.formatter ? (
                      d.formatter(request?.chattel?.[d.value])
                    ) : (
                      <p className="" key={i}>
                        {request?.chattel?.[d.value] || "--"}
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
            <Col sm={12}>
              <h4 className="my-3">Service Information</h4>
            </Col>
            {Service_Information.map((d, i) => (
              <Col sm={12} md={6} className="">
                <Row>
                  <Col xs={4} sm={4} className="">
                    <p key={i} className="font-weight-bold">
                      {d.label}
                    </p>
                  </Col>
                  <Col xs={8} sm={8}>
                    {d?.formatter ? (
                      d.formatter(request?.service?.[d.value])
                    ) : (
                      <p className="" key={i}>
                        {request?.service?.[d.value] || "--"}
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
            <Col sm={12} md={6} className="">
              <Row>
                <Col xs={4} sm={4} className="">
                  <p className="font-weight-bold">Notes</p>
                </Col>
                <Col xs={8} sm={8}>
                  <p className="">{request?.notes || "--"}</p>
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <h4 className="my-2">Owners</h4>
            </Col>
            {request?.owners?.length > 0 ? (
              request?.owners?.map((owner, index) => (
                <Col sm={12} className="mt-0">
                  <OwnerCard
                    owner={owner}
                    index={index}
                    showEditButtons={false}
                  />
                </Col>
              ))
            ) : (
              <Col sm={12}>
                <h5 className="text-center my-0">No Owner Found</h5>
              </Col>
            )}
          </Row>
        </ModalBody>
      </>
    </Modal>
  );
};

export default RequestDetails;
