import React, { useState } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { createDmv, updateDmv } from "../../helpers/backend_helper";

const DmvModal = ({
  dmv = {},
  isOpen,
  setIsOpen,
  setActiveDmvIndex = () => {},
  loadDmvData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log("values", values);
    setIsLoading(true);
    try {
      if (dmv?.id) {
        await updateDmv(values);
      } else {
        await createDmv(values);
      }
      await loadDmvData(false);
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = (formik) => {
    formik.resetForm();
    setIsOpen(false);
    setActiveDmvIndex(-1);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
      style={{
        width: "700px",
        maxWidth: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: dmv?.id || "",
          section: dmv?.section || "",
          email: dmv?.email || "",
          contact: dmv?.contact || "",
          phoneNumber: dmv?.phoneNumber || "",
          faxNumber: dmv?.faxNumber || "",
          notes: dmv?.notes || "",
          address: {
            street: dmv?.address?.street || "",
            city: dmv?.address?.city || "",
            state: dmv?.address?.state || "",
            zip: dmv?.address?.zip || "",
          },
        }}
        validationSchema={Yup.object({
          id: Yup.string()
            .required("Please Enter ID")
            .matches(/^[a-zA-Z]+$/, "ID must only contain letters"),
          section: Yup.string(),
          email: Yup.string().matches(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            "Invalid Email"
          ),
          contact: Yup.string(),
          phoneNumber: Yup.string().matches(
            /^[+\-0-9() ]+$/,
            "Invalid phone number format"
          ),
          faxNumber: Yup.string().matches(
            /^[+\-0-9() ]+$/,
            "Invalid fax number format"
          ),
          notes: Yup.string(),
          address: Yup.object().shape({
            street: Yup.string(),
            city: Yup.string(),
            state: Yup.string().length(2, "State must be a two-letter string"),
            zip: Yup.string().matches(
              /^\d{5}(-\d{4})?$/,
              "Zip must be formatted as 5 digits or 5 digits followed by a hyphen and 4 digits (e.g., 12345 or 12345-6789)"
            ),
          }),
        })}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">{dmv?.id ? "Edit" : "Add"} DMV</h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="id" className="form-label">
                        ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="id"
                        type="text"
                        className="form-control"
                        placeholder="Enter ID"
                        id="id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.id || ""}
                        disabled={dmv?.id}
                        invalid={
                          validation.touched.id && validation.errors.id
                            ? true
                            : false
                        }
                      />
                      {validation.touched.id && validation.errors.id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="section" className="form-label">
                        Section
                      </Label>
                      <Input
                        name="section"
                        type="text"
                        className="form-control"
                        placeholder="Enter Section"
                        id="section"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.section || ""}
                        invalid={
                          validation.touched.section &&
                          validation.errors.section
                            ? true
                            : false
                        }
                      />
                      {validation.touched.section &&
                      validation.errors.section ? (
                        <FormFeedback type="invalid">
                          {validation.errors.section}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="email" className="form-label">
                        Email
                      </Label>
                      <Input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        id="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="contact" className="form-label">
                        Contact
                      </Label>
                      <Input
                        name="contact"
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact"
                        id="contact"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contact || ""}
                        invalid={
                          validation.touched.contact &&
                          validation.errors.contact
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </Label>
                      <Input
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        id="phoneNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNumber || ""}
                        invalid={
                          validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="faxNumber" className="form-label">
                        Fax Number
                      </Label>
                      <Input
                        name="faxNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter Fax Number"
                        id="faxNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.faxNumber || ""}
                        invalid={
                          validation.touched.faxNumber &&
                          validation.errors.faxNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.faxNumber &&
                      validation.errors.faxNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.faxNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div>
                      <Label htmlFor="notes" className="form-label">
                        Notes
                      </Label>
                      <Input
                        name="notes"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Notes"
                        id="notes"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.notes || ""}
                        invalid={
                          validation.touched?.address?.notes &&
                          validation.errors?.address?.notes
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address?.notes &&
                      validation.errors.address?.notes ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address?.notes}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <h3 className="h4 mb-0">Address</h3>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="address.street" className="form-label">
                        Street
                      </Label>
                      <Input
                        name="address.street"
                        type="text"
                        className="form-control"
                        placeholder="Enter Street"
                        id="address.street"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address.street || ""}
                        invalid={
                          validation.touched?.address?.street &&
                          validation.errors?.address?.street
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address?.street &&
                      validation.errors.address?.street ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address?.street}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="address.city" className="form-label">
                        City
                      </Label>
                      <Input
                        name="address.city"
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        id="address.city"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address.city || ""}
                        invalid={
                          validation.touched?.address?.city &&
                          validation.errors?.address?.city
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address?.city &&
                      validation.errors.address?.city ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address?.city}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="address.state" className="form-label">
                        State
                      </Label>
                      <Input
                        name="address.state"
                        type="text"
                        className="form-control"
                        placeholder="Enter State"
                        id="address.state"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address.state || ""}
                        invalid={
                          validation.touched?.address?.state &&
                          validation.errors?.address?.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address?.state &&
                      validation.errors.address?.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address?.state}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div>
                      <Label htmlFor="address.zip" className="form-label">
                        Zip
                      </Label>
                      <Input
                        name="address.zip"
                        type="text"
                        className="form-control"
                        placeholder="Enter ZIP"
                        id="address.zip"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address.zip || ""}
                        invalid={
                          validation.touched?.address?.zip &&
                          validation.errors?.address?.zip
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address?.zip &&
                      validation.errors.address?.zip ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address?.zip}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button
                        color="primary"
                        className="d-inline-flex align-items-center gap-2"
                        disabled={isLoading}
                      >
                        {isLoading && <Spinner size="sm"> Loading... </Spinner>}{" "}
                        <span>{dmv?.id ? "Update Dmv" : "Add Dmv"}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default DmvModal;
